export const AudioAction = {
    SET_FILES : 'SET_FILES',
    ADD_FILE: 'ADD_FILE',
    REMOVE_FILE: 'REMOVE_FILE',
    SET_LOADING: 'SET_LOADING',
    SET_SOUND: 'SET_SOUND',
    SET_WAVESURFER: 'SET_WAVESURFER',
    ADD_SELECTION: 'ADD_SELECTION',
    UPDATE_FILE_INFO: 'UPDATE_FILE_INFO',
    SET_CURRENT_TIME: 'SET_CURRENT_TIME',
}