import React from 'react'
import Header from "../components/Header";

export default function PageNotFound (){
    return (
        <>
            <Header />
            <div>
            Error 404
            </div>
        </>
    )
}